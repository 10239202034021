import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VSnackbar,
    {
      attrs: { center: true, bottom: true, color: _vm.notification.color },
      model: {
        value: _vm.notification.show,
        callback: function ($$v) {
          _vm.$set(_vm.notification, "show", $$v)
        },
        expression: "notification.show",
      },
    },
    [
      _c(
        VRow,
        [
          _c(VCol, { staticClass: "text-center", attrs: { cols: "9" } }, [
            _vm._v(" " + _vm._s(_vm.notification.text) + " "),
          ]),
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c(
                VBtn,
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.notification.show = false
                    },
                  },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }