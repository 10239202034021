import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VAppBar,
        {
          staticClass: "hidden-xs-only",
          attrs: { app: "", "clipped-left": "" },
        },
        [
          _c("a", { attrs: { href: "https://nettica.com" } }, [
            _c("img", {
              staticClass: "mr-3",
              attrs: {
                src: "/logo.png",
                height: "50",
                width: "50",
                alt: "nettica",
              },
            }),
          ]),
          _c(VToolbarTitle, { attrs: { to: "/" } }, [
            _vm._v(" " + _vm._s(_vm.title)),
          ]),
          _c(VSpacer),
          _c(
            VToolbarItems,
            [
              _c(
                VBtn,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showServices,
                      expression: "showServices",
                    },
                  ],
                  attrs: { to: "/services", title: "services" },
                },
                [
                  _vm._v(" Services "),
                  _c(VIcon, { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-weather-cloudy"),
                  ]),
                ],
                1
              ),
              _c(
                VBtn,
                { attrs: { to: "/networks", right: "", title: "networks" } },
                [
                  _vm._v(" Networks "),
                  _c("span", { staticClass: "material-symbols-outlined" }, [
                    _vm._v("hub"),
                  ]),
                ]
              ),
              _c(
                VBtn,
                { attrs: { to: "/devices", title: "devices" } },
                [
                  _vm._v(" Devices "),
                  _c(VIcon, { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-devices"),
                  ]),
                ],
                1
              ),
              _c(
                VBtn,
                { attrs: { to: "/accounts", title: "account" } },
                [
                  _vm._v(" Account "),
                  _c(VIcon, { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-account-group"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VMenu,
            {
              attrs: { left: "", bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        VBtn,
                        _vm._g({ attrs: { icon: "" } }, on),
                        [
                          _c(VAvatar, { attrs: { size: "36" } }, [
                            _c("img", {
                              attrs: {
                                src: _vm.picture,
                                height: "36",
                                width: "36",
                                alt: _vm.name,
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                VCard,
                {
                  staticClass: "mx-auto",
                  attrs: { "max-width": "344", outlined: "" },
                },
                [
                  _c(
                    VListItem,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAuthenticated,
                          expression: "isAuthenticated",
                        },
                      ],
                      attrs: { "three-line": "" },
                    },
                    [
                      _c(
                        VListItemContent,
                        [
                          _c("div", { staticClass: "overline mb-4" }, [
                            _vm._v("connected as"),
                          ]),
                          _c(
                            VListItemTitle,
                            { staticClass: "headline mb-1" },
                            [
                              _vm._v(_vm._s(_vm.name) + " "),
                              _c(VAvatar, { attrs: { size: "64" } }, [
                                _c("img", {
                                  attrs: {
                                    alt: "name",
                                    src: _vm.picture,
                                    height: "64",
                                    width: "64",
                                    alt: _vm.name,
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _c(VListItemSubtitle, [
                            _vm._v("Email: " + _vm._s(_vm.email)),
                          ]),
                          _c(VListItemSubtitle, [
                            _vm._v("Issuer: " + _vm._s(_vm.issuer)),
                          ]),
                          _c(VListItemSubtitle, [
                            _vm._v(
                              "Issued at: " +
                                _vm._s(_vm._f("formatDate")(_vm.issuedAt))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(
                        VBtn,
                        { attrs: { small: "" }, on: { click: _vm.mylogout } },
                        [
                          _vm._v(" logout "),
                          _c(
                            VIcon,
                            { attrs: { small: "", right: "", dark: "" } },
                            [_vm._v("mdi-logout")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VAppBar,
        {
          staticClass: "hidden-sm-and-up",
          attrs: { app: "", "clipped-left": "" },
        },
        [
          _c("a", { attrs: { href: "https://nettica.com" } }, [
            _c("img", {
              staticClass: "mr-3",
              attrs: {
                src: "/logo.png",
                height: "50",
                width: "50",
                alt: "nettica",
              },
            }),
          ]),
          _c(VToolbarTitle, { attrs: { to: "/" } }, [
            _vm._v(" " + _vm._s(_vm.title)),
          ]),
          _c(VSpacer),
          _c(
            VBtn,
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.myShowMenu()
                },
              },
            },
            [_c(VIcon, [_vm._v("mdi-menu")])],
            1
          ),
        ],
        1
      ),
      _vm.friendly
        ? _c(
            "div",
            { staticStyle: { width: "100%", position: "absolute" } },
            [
              _c("div", { staticStyle: { height: "64px", width: "100%" } }),
              _c(VAlert, { attrs: { type: "info", color: "#336699" } }, [
                _vm._v(
                  " Welcome to the Admin! Click on the menu above to add service, create networks, add devices, and invite others to your account. "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        VNavigationDrawer,
        {
          staticClass: "hidden-sm-and-up",
          attrs: { app: "", clipped: "", right: "" },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            VList,
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                VListItem,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showServices,
                      expression: "showServices",
                    },
                  ],
                  attrs: {
                    "prepend-icon": "mdi-weather-cloudy",
                    title: "Services",
                    value: "services",
                    to: "/services",
                  },
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-weather-cloudy")])],
                    1
                  ),
                  _c(VListItemTitle, [_vm._v("Services")]),
                ],
                1
              ),
              _c(
                VListItem,
                {
                  attrs: {
                    title: "Networks",
                    value: "networks",
                    to: "/networks",
                  },
                },
                [
                  _c(VListItemIcon, [
                    _c("span", { staticClass: "material-symbols-outlined" }, [
                      _vm._v("hub"),
                    ]),
                  ]),
                  _c(VListItemTitle, [_vm._v("Networks")]),
                ],
                1
              ),
              _c(
                VListItem,
                {
                  attrs: {
                    "prepend-icon": "mdi-devices",
                    title: "Devices",
                    value: "devices",
                    to: "/devices",
                  },
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-devices")])],
                    1
                  ),
                  _c(VListItemTitle, [_vm._v("Devices")]),
                ],
                1
              ),
              _c(
                VListItem,
                {
                  attrs: {
                    "prepend-icon": "mdi-account-group",
                    title: "Accounts",
                    value: "/accounts",
                    to: "/accounts",
                  },
                },
                [
                  _c(
                    VListItemIcon,
                    [_c(VIcon, [_vm._v("mdi-account-group")])],
                    1
                  ),
                  _c(VListItemTitle, [_vm._v("Accounts")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }