import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VNavigationDrawer,
    { attrs: { app: "", clipped: "", permanent: "" } },
    [
      _c(
        VList,
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            VListItem,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showServices,
                  expression: "showServices",
                },
              ],
              attrs: {
                "prepend-icon": "mdi-weather-cloudy",
                title: "Services",
                value: "services",
                to: "/services",
              },
            },
            [
              _c(
                VListItemIcon,
                [_c(VIcon, [_vm._v("mdi-weather-cloudy")])],
                1
              ),
              _c(VListItemTitle, [_vm._v("Services")]),
            ],
            1
          ),
          _c(
            VListItem,
            {
              attrs: {
                "prepend-icon": "mdi-network-outline",
                title: "Networks",
                value: "networks",
                to: "/networks",
              },
            },
            [
              _c(
                VListItemIcon,
                [_c(VIcon, [_vm._v("mdi-network-outline")])],
                1
              ),
              _c(VListItemTitle, [_vm._v("Networks")]),
            ],
            1
          ),
          _c(
            VListItem,
            {
              attrs: {
                "prepend-icon": "mdi-devices",
                title: "Devices",
                value: "devices",
                to: "/devices",
              },
            },
            [
              _c(
                VListItemIcon,
                [_c(VIcon, [_vm._v("mdi-devices")])],
                1
              ),
              _c(VListItemTitle, [_vm._v("Devices")]),
            ],
            1
          ),
          _c(
            VListItem,
            {
              attrs: {
                "prepend-icon": "mdi-account-group",
                title: "Accounts",
                value: "/accounts",
                to: "/accounts",
              },
            },
            [
              _c(
                VListItemIcon,
                [_c(VIcon, [_vm._v("mdi-account-group")])],
                1
              ),
              _c(VListItemTitle, [_vm._v("Accounts")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }