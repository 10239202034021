import { VApp } from 'vuetify/lib/components/VApp';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VApp,
    { attrs: { id: "inspire" } },
    [
      _c(
        VLayout,
        [
          _c("Notification", { attrs: { notification: _vm.notification } }),
          _c("Header", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isAuthenticated,
                expression: "isAuthenticated",
              },
            ],
          }),
          _c(
            VMain,
            { staticStyle: { padding: "0px" } },
            [_c("router-view")],
            1
          ),
          _c("Footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }