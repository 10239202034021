import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VFooter,
    { attrs: { app: "" } },
    [
      _c(
        VRow,
        { attrs: { justify: "start", "no-gutters": "" } },
        [
          _c(
            VCol,
            [
              _c("small", [
                _vm._v(
                  "Copyright © " +
                    _vm._s(new Date().getFullYear()) +
                    " nettica.com"
                ),
              ]),
              _c(VSpacer),
            ],
            1
          ),
          _c(VCol, { attrs: { align: "right" } }, [
            _c("small", [_vm._v("Version: " + _vm._s(_vm.version))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }